import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import { setCurrentData } from '../../../app/redux/slices/currentDataSlice'
import { setDeleteCurrentGeo } from '../../geo/api/currentGeoSlice'
import { webSitesProps } from '../../../shared/types/types'
import { seCLearWebSite } from './currentWebsiteUpdateSlice'

import { setCurrentEditorOpen } from './isOpenEditorSlice'

import { setCurrentDeleteTemplate } from '../../../app/redux/slices/template/currentTemplateSlice'
import { setClearDataTemplate } from '../../../app/redux/slices/template/templateOneGetSlice'

import { fetchCreateWebSite } from './websiteCreateSlice'
import { fetchGetWebSite } from './websiteGetSlice'
import { setDeleteCurrentVertical } from '../../vertical/api/currentVerticalSlice'

export const createAndSeeNewWebsiteSlice =
    (someObject: webSitesProps) =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            await dispatch(fetchCreateWebSite(someObject)).unwrap()
            await dispatch(fetchGetWebSite()).unwrap()
            dispatch(setCurrentDeleteTemplate())
            dispatch(setDeleteCurrentGeo())
            dispatch(setDeleteCurrentVertical())
            dispatch(setCurrentData(''))
            dispatch(setClearDataTemplate())
            dispatch(setCurrentEditorOpen(false))
            dispatch(seCLearWebSite())
            window.location.reload()
        } catch (error) {
            console.error('Ошибка выполнения:', error)
        }
    }
