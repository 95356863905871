import { Route, Routes } from 'react-router-dom'
import CardTempPage from './pages/CardTempPage/ui/CardTempPage'
import HeaderLayout from './pages/HeaderLayout/HeaderLayout'
import NotFoundPage from './pages/NotFound/NotFoundPage'
import TemplatePage from './pages/template/TemplatePage'
import Components from './widgets/components/Components'
import { Editor } from './widgets/Editor/Editor'
function App() {
	return (
		<>
			<Routes>
				<Route path='/' element={<HeaderLayout />}>
					<Route path='/websites' element={<Editor />} />
					<Route path='/components' element={<Components />} />
					<Route path='/template' element={<TemplatePage />} />

					<Route path='*' element={<NotFoundPage />} />
				</Route>
				<Route path='/template/:id' element={<CardTempPage />} />
			</Routes>
		</>
	)
}

export default App
