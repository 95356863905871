import { Dropdown } from '@mui/base/Dropdown'
import { Menu, MenuListboxSlotProps } from '@mui/base/Menu'
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton'
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem'
import { CssTransition } from '@mui/base/Transitions'
import { PopupContext } from '@mui/base/Unstable_Popup'
import { styled } from '@mui/system'
import * as React from 'react'
import { useDispatch } from 'react-redux'

import { setCurrentGroup } from '../../app/redux/slices/group/currentGroupSlice'
import { setCurrentTemplate } from '../../app/redux/slices/template/currentTemplateSlice'

import { AppDispatch } from '../../app/redux/store'
import { setCurrentGeo } from '../../entities/geo/api/currentGeoSlice'
import { setCurrentVertical } from '../../entities/vertical/api/currentVerticalSlice'
import {
	DropdownPropsType,
	GeoProps,
	GroupProps,
	templateProps,
	VerticalProps,
} from '../types/types'

const MenuIntroduction: React.FC<DropdownPropsType> = ({
	type,
	list,
	defaultSelected,
}) => {
	const [selected, setSelected] = React.useState<any>(null)

	const dispatch = useDispatch<AppDispatch>()
	React.useEffect(() => {
		if (!defaultSelected) {
			setSelected(`${type} не выбрано`)
		} else {
			setSelected(defaultSelected)
			list.map((el: any) => {
				if (el.nameGroup === defaultSelected) {
					dispatch(setCurrentGroup(el))
				}
			})
		}
	}, [defaultSelected])

	function isGeoProps(
		menuItem: GeoProps | templateProps | VerticalProps | GroupProps
	): menuItem is GeoProps {
		return (menuItem as GeoProps).geo !== undefined
	}

	function isTemplateProps(
		menuItem: GeoProps | templateProps | VerticalProps | GroupProps
	): menuItem is templateProps {
		return (menuItem as templateProps).name !== undefined
	}
	function isGroupProps(
		menuItem: GeoProps | templateProps | VerticalProps | GroupProps
	): menuItem is GroupProps {
		return (menuItem as GroupProps).nameGroup !== undefined
	}

	function isVerticalProps(
		menuItem: GeoProps | templateProps | VerticalProps | GroupProps
	): menuItem is VerticalProps {
		return (menuItem as VerticalProps).vertical !== undefined
	}

	const createHandleMenuClick = (
		menuItem: GeoProps | templateProps | VerticalProps | GroupProps
	) => {
		return () => {
			if (isGeoProps(menuItem)) {
				dispatch(setCurrentGeo(menuItem))
				setSelected(menuItem.geo)
			} else if (isTemplateProps(menuItem)) {
				setSelected(menuItem.name)
				dispatch(setCurrentTemplate(menuItem))
			} else if (isVerticalProps(menuItem)) {
				setSelected(menuItem.vertical)
				dispatch(setCurrentVertical(menuItem))
			} else if (isGroupProps(menuItem)) {
				setSelected(menuItem.nameGroup)
				dispatch(setCurrentGroup(menuItem))
			}
		}
	}

	return (
		<Dropdown>
			<MenuButton>{selected}</MenuButton>
			<Menu slots={{ listbox: AnimatedListbox }}>
				{type === 'vertical' &&
					list.map((el: any) => {
						return (
							<MenuItem key={el.id} onClick={createHandleMenuClick(el)}>
								{el.vertical}
							</MenuItem>
						)
					})}
				{type === 'geo' &&
					list.map((el: any) => {
						return (
							<MenuItem key={el.id} onClick={createHandleMenuClick(el)}>
								{el.geo}
							</MenuItem>
						)
					})}
				{type === 'template' &&
					list.map((el: any) => {
						return (
							<MenuItem key={el.id} onClick={createHandleMenuClick(el)}>
								{el.name}
							</MenuItem>
						)
					})}
				{type === 'group' &&
					list.map((el: any) => {
						return (
							<MenuItem key={el.id} onClick={createHandleMenuClick(el)}>
								{el.nameGroup}
							</MenuItem>
						)
					})}
			</Menu>
		</Dropdown>
	)
}

const blue = {
	50: '#F0F7FF',
	100: '#C2E0FF',
	200: '#99CCF3',
	300: '#66B2FF',
	400: '#3399FF',
	500: '#007FFF',
	600: '#0072E6',
	700: '#0059B3',
	800: '#004C99',
	900: '#003A75',
}

const grey = {
	50: '#F3F6F9',
	100: '#E5EAF2',
	200: '#DAE2ED',
	300: '#C7D0DD',
	400: '#B0B8C4',
	500: '#9DA8B7',
	600: '#6B7A90',
	700: '#434D5B',
	800: '#303740',
	900: '#1C2025',
}

const Listbox = styled('ul')(
	({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.575rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width:300px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  z-index: 1;

  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `
)

const AnimatedListbox = React.forwardRef(function AnimatedListbox(
	props: MenuListboxSlotProps,
	ref: React.ForwardedRef<HTMLUListElement>
) {
	const { ownerState, ...other } = props
	const popupContext = React.useContext(PopupContext)

	if (popupContext == null) {
		throw new Error(
			'The `AnimatedListbox` component cannot be rendered outside a `Popup` component'
		)
	}

	const verticalPlacement = popupContext.placement.split('-')[0]

	return (
		<CssTransition
			className={`placement-${verticalPlacement}`}
			enterClassName='open'
			exitClassName='closed'
		>
			<Listbox {...other} ref={ref} />
		</CssTransition>
	)
})

const MenuItem = styled(BaseMenuItem)(
	({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &:focus {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }
  `
)

const MenuButton = styled(BaseMenuButton)(
	({ theme }) => `
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 8px 16px;
  border-radius: 8px;
  color: white;
  transition: all 150ms ease;
  cursor: pointer;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[200] : grey[900]};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:active {
    background: ${theme.palette.mode === 'dark' ? grey[700] : grey[100]};
  }

  &:focus-visible {
    box-shadow: 0 0 0 4px ${theme.palette.mode === 'dark' ? blue[300] : blue[200]};
    outline: none;
  }
  `
)

export default MenuIntroduction
