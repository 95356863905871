import { Modal } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import styled from '@mui/system/styled'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setIsUpdateTemp } from '../../app/redux/slices/template/isUpdateEventSlice'
import {
	errorTemplate,
	loadingTemplate,
	template,
} from '../../app/redux/slices/template/templateGetSlice'
import {
	errorGetOneTemplateData,
	fetchOneTemplate,
	loadingGetOneTemplateData,
	templateGetOneData,
} from '../../app/redux/slices/template/templateOneGetSlice'
import {
	errorGetOnePrevTemplateData,
	fetchOneSecondTemplate,
	setClearDataTemplatePrev,
	templateGetOnePrevData,
} from '../../app/redux/slices/template/templateOneSecondGetSlice'
import { AppDispatch } from '../../app/redux/store'
import { dataComponents } from '../../entities/component/api/componentsSlice'
import { findComponents } from '../../features/findComponents'
import ResponsiveModal from './Alert'

interface CartProps {
	id: number
	name: string
}

interface isOpenPreviewProps {
	id: null | number
	state: boolean
}

const CardTemplate: React.FC<CartProps> = ({ id, name }) => {
	const [open, setOpen] = useState<boolean>(false)
	const [modalContent, setModalContent] = useState<string>('')
	const dispatch = useDispatch<AppDispatch>()
	const [isClickedUpdated, setIsClickedUpdated] = useState<boolean>(false)
	const [isOpenPreview, setIsOpenPreview] = useState<isOpenPreviewProps>({
		state: false,
		id: null,
	})
	const templateGetOneDataLocal = useSelector(templateGetOneData)
	const errorGetOneTemplateDataLocal = useSelector(errorGetOneTemplateData)
	const loadingGetOneTemplateDataLocal = useSelector(loadingGetOneTemplateData)

	const loadingTemplateLocal = useSelector(loadingTemplate)
	const errorTemplateLocal = useSelector(errorTemplate)
	const templateLocal = useSelector(template)
	const dataComponent = useSelector(dataComponents)
	const iframeRef = useRef<HTMLIFrameElement>(null)

	const loadingGetPrevOneTemplateDataLocal = useSelector(
		loadingGetOneTemplateData
	)
	const errorGetOnePrevTemplateDataLocal = useSelector(
		errorGetOnePrevTemplateData
	)
	const templateGetOnePrevDataLocal = useSelector(templateGetOnePrevData)

	const handleOpen = () => {
		//из-за этой функции срабатывает editor
		dispatch(fetchOneSecondTemplate(id))
		setIsOpenPreview({ state: true, id })
		setOpen(true)
	}

	useEffect(() => {
		if (
			templateGetOnePrevDataLocal &&
			isOpenPreview.state &&
			isOpenPreview.id
		) {
			const dataIds: number[] = templateGetOnePrevDataLocal.components || []
			const result = findComponents(dataIds, dataComponent)

			let content = result.map((item: { html: string }) => item.html).join('')

			const resultLinks = result.map(item => item.links)
			const scriptLinks: string[] = []
			const styleLinks: string[] = []

			resultLinks.forEach(links => {
				if (links.scriptLinks) {
					scriptLinks.push(...links.scriptLinks)
				}
				if (links.styleLinks) {
					styleLinks.push(...links.styleLinks)
				}
			})

			let contentWithLinks = '<div>'
			styleLinks.forEach(link => {
				contentWithLinks += `<link rel="stylesheet" href="${link}">`
			})
			scriptLinks.forEach(link => {
				contentWithLinks += `<script src="${link}"></script>`
			})
			contentWithLinks += content
			contentWithLinks += '</div>'

			// Set the modal content
			setModalContent(contentWithLinks)
		}
	}, [templateGetOnePrevDataLocal, isOpenPreview, dataComponent, open])

	useEffect(() => {
		if (iframeRef.current && modalContent) {
			const doc =
				iframeRef.current.contentDocument ||
				iframeRef.current.contentWindow?.document
			if (doc) {
				doc.open()
				doc.write(modalContent)
				doc.close()
			}
		}
	}, [modalContent])

	const handleClose = () => {
		setOpen(false)

		setIsOpenPreview({ state: false, id: 0 })
		dispatch(
			setIsUpdateTemp({
				id: null,
				state: false,
				name: '',
				components: [],
			})
		)
		dispatch(setClearDataTemplatePrev())
	}

	const updateWeb = () => {
		setIsClickedUpdated(true)
		dispatch(fetchOneTemplate(id))
		//нужно написать thunk
		//  1)dispatch(fetchOneTemplate(id));
		// 2)
	}

	useEffect(() => {
		if (
			isClickedUpdated === true &&
			templateGetOneDataLocal.id !== null &&
			loadingGetOneTemplateDataLocal === false
		) {
			dispatch(
				setIsUpdateTemp({
					id: templateGetOneDataLocal.id,
					components: templateGetOneDataLocal.components
						? templateGetOneDataLocal.components
						: [],
					state: true,
					name: templateGetOneDataLocal.name,
				})
			)
		}
	}, [
		isClickedUpdated,
		templateGetOneDataLocal,
		loadingGetOneTemplateDataLocal,
		errorGetOneTemplateDataLocal,
	])
	const navigate = useNavigate()

	return (
		<Card sx={{ maxWidth: 300, margin: 0.5 }}>
			<CardContent sx={{ display: 'flex', justifyContent: 'space-between' }}>
				{/* <Typography variant='h5' component='div'>
                    {id}
                </Typography> */}
				<Typography variant='h5' component='div'>
					{name}
				</Typography>
				<ResponsiveModal
					typeContent={'template'}
					type={'delete'}
					idTemplate={id}
					content={'Вы действительно хотите удалить компонент?'}
				/>
			</CardContent>
			<CardActions>
				<Button onClick={handleOpen} size='small'>
					увидеть превью
				</Button>
				<Button onClick={updateWeb} size='small'>
					изменить
				</Button>
				<Button
					onClick={() => {
						window.location.href = `${process.env.REACT_APP_FRONT}/template/${id}`
					}}
					size='small'
				>
					открыть
				</Button>
			</CardActions>

			<Modal
				aria-labelledby='unstyled-modal-title'
				aria-describedby='unstyled-modal-description'
				open={open}
				onClose={handleClose}
				slots={{ backdrop: StyledBackdrop }}
			>
				<ModalContent sx={{ width: '50vw' }}>
					<h2 id='unstyled-modal-title' className='modal-title'>
						{name}
					</h2>
					<iframe
						ref={iframeRef}
						style={{
							width: '100%',
							height: '500px',
							border: 'none',
						}}
					/>
				</ModalContent>
			</Modal>
		</Card>
	)
}

export default CardTemplate

const ModalContent = styled('div')`
	font-family: 'IBM Plex Sans', sans-serif;
	font-weight: 500;
	text-align: start;
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 8px;
	background-color: #fff;
	border-radius: 8px;
	border: 1px solid #ccc;
	box-shadow: 0 4px 12px rgb(0 0 0 / 0.2);
	padding: 24px;
	color: #333;
`

const StyledBackdrop = styled('div')`
	z-index: -1;
	position: fixed;
	inset: 0;
	background-color: rgb(0 0 0 / 0.5);
	-webkit-tap-highlight-color: transparent;
`
