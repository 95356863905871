import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
	errorOneComponentData,
	fetchComponentsThunk,
	loadingComponentData,
} from '../../../app/redux/slices/component/getOneComponentSlice'
import {
	errorGetOneTemplateData,
	fetchOneTemplate,
	loadingGetOneTemplateData,
	templateGetOneData,
} from '../../../app/redux/slices/template/templateOneGetSlice'
import { AppDispatch, RootState } from '../../../app/redux/store'
import NotFoundPage from '../../../pages/NotFound/NotFoundPage'

const CardTemp = () => {
	interface FormData {
		id: number
		html: string
		name: string
		group: number
		links: {
			styleLinks: string[]
			scriptLinks: string[]
		}
		createdAt: string
		updatedAt: string
	}

	const [componentsLocal, setComponentsLocal] = useState<null | number[]>(null)
	const [componentsData, setComponentsData] = useState<FormData[]>([]) // Массив для накопления данных
	const [combinedHtml, setCombinedHtml] = useState<string>('') // Состояние для хранения итогового HTML

	const templateGetOneDataLocal = useSelector(templateGetOneData)
	const loadingGetOneTemplateDataLocal = useSelector(loadingGetOneTemplateData)
	const errorGetOneTemplateDataLocal = useSelector(errorGetOneTemplateData)

	const params = useParams()
	const dispatch = useDispatch<AppDispatch>()
	const loadingComponentDataLocal = useSelector<RootState>(loadingComponentData)
	const errorOneComponentDataLocal = useSelector<RootState>(
		errorOneComponentData
	)
	const componentsDataLocal = useSelector(
		(state: RootState) => state.componentFetchGetOneSlice.components
	)

	useEffect(() => {
		if (
			errorOneComponentDataLocal == null &&
			loadingComponentDataLocal === false
		) {
			setComponentsData(prevData => [...prevData, ...componentsDataLocal]) // Разворачиваем массив компонентов
		}
	}, [
		componentsDataLocal,
		errorOneComponentDataLocal,
		loadingComponentDataLocal,
	])

	// Получение данных шаблона
	useEffect(() => {
		if (
			errorGetOneTemplateDataLocal === null &&
			loadingGetOneTemplateDataLocal === false &&
			templateGetOneDataLocal
		) {
			setComponentsLocal(templateGetOneDataLocal.components)
		}
	}, [errorGetOneTemplateDataLocal, loadingGetOneTemplateDataLocal])

	// Получение данных по id
	useEffect(() => {
		if (params && params.id) {
			dispatch(fetchOneTemplate(Number(params.id)))
		}
	}, [params])

	// Запуск запросов для компонентов
	useEffect(() => {
		if (componentsLocal !== null) {
			dispatch(fetchComponentsThunk(componentsLocal))
		}
	}, [componentsLocal, dispatch])

	// Объединение HTML и ссылок на стили/скрипты

	useEffect(() => {
		if (componentsData && componentsData.length > 0) {
			// Объединяем все html компоненты
			const combinedHtmlContent = componentsData
				.map(component => component.html)
				.join('\n')

			// Собираем все ссылки на стили

			const styleLinks = componentsData
				.flatMap(component => component.links.styleLinks)
				.map(link => `<link rel="stylesheet" href="${link}" />`)
				.join('\n')

			// Собираем все скрипты
			const scriptLinks = componentsData
				.flatMap(component => component.links.scriptLinks)
				.map(link => `<script src="${link}" async></script>`)
				.join('\n')

			// Создаем итоговый HTML документ с скриптами
			const fullHtml = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Combined HTML</title>
          ${styleLinks}
        </head>
        <body>
          ${combinedHtmlContent}
          ${scriptLinks}
        </body>
        </html>
      `

			setCombinedHtml(fullHtml)
		}
	}, [componentsData])

	// Используем ref для <iframe>
	const iframeRef = useRef<HTMLIFrameElement>(null)

	useEffect(() => {
		if (iframeRef.current) {
			const iframeDoc = iframeRef.current.contentDocument
			if (iframeDoc) {
				iframeDoc.open()
				iframeDoc.write(combinedHtml)
				iframeDoc.close()
			}
		}
	}, [combinedHtml])

	return (
		<div>
			{!templateGetOneDataLocal && <NotFoundPage />}
			{loadingComponentDataLocal === false &&
			errorOneComponentDataLocal == null ? (
				<div>
					<iframe
						ref={iframeRef}
						style={{ width: '100%', height: '100vh', border: 'none' }}
					/>
				</div>
			) : (
				<div>loading...</div>
			)}
		</div>
	)
}

export default CardTemp
